.editorWrapper {
  height: 100%;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  background-color: #1e1e1e;
  color: white;
}

.editorWrapper .actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #1e1e1e;
  color: white;
}

.editor {
  height: 100%;
  flex-basis: 100%;
}

.toolbar {
  justify-content: space-between;
}

.main {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  max-height: calc(100vh - 64px);
  height: calc(100vh - 64px);
}

.leftControl {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}
.rightControl {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.actions {
  padding: 10px;
}
.actions .actionButtons a {
  margin-left: 10px;
}

.caseButtons {
  position: absolute;
  z-index: 999;
  background: white;
  left: 100px;
}
